import React from 'react'
import './about.css'
import cover from './img/potter.png'

import {decor} from './WorksData' 

function About() {
    return (
        <div className="general">
            <div className="background">
                <div className="works-title">
                    The Artist
                </div>
                <div className="altx">
                    <div className="altxline1"></div>
                    <div className="altxline2"></div>
                </div>
                <div className="about-cover">
                    <img src={cover} alt="about-photo" className="about-photo" />
                </div>
                <div className="about-info">
                    Chialing Hsieh is an artist from Roanoke, Virginia.
                </div>
                <div>
                    Test here
                    {decor}
                </div>
            </div>
        </div>
    )
}

export default About
