import React from 'react'
import './cart.css'

function Cart() {
    return (
        <div className="general">
            Cart
        </div>
    )
}

export default Cart
