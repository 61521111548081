import React, { useState } from 'react'
import './works.css'
// import plate0 from './img/plate0.jpg'
import cup0 from './img/cup0.JPG'
import bowl0 from './img/bowl0.jpg'
import decor0 from './img/decor0.JPG'
import decor1 from './img/decor1.JPG'
import decor2 from './img/decor2.JPG'
import decor3 from './img/decor3.JPG'
import decor4 from './img/decor4.JPG'
import { Link } from 'react-scroll'

import pl1 from './img/test/1.JPG'


function Works() {
    // const [plates, setplates] = useState([
    //     {
    //         id: 1,
    //         label: "plate",
    //         count: 2,
    //         price: "10.00",
    //         amtBought: 0,
    //         img: require('./img/picture1.jpg')
    //     },
    //     {
    //         id: 2,
    //         label: "plate",
    //         count: 1,
    //         price: "11.00",
    //         amtBought: 0,
    //         img: require('./img/picture1.jpg')
    //     },
    //     {
    //         id: 3,
    //         label: "plate",
    //         count: 1,
    //         price: "12.00",
    //         amtBought: 0,
    //         img: require('./img/picture1.jpg')
    //     }
    // ])
    // const [bowls, setbowls] = useState([
    //     {
    //         id: 4,
    //         label: "bowl",
    //         count: 1,
    //         price: "10.00",
    //         amtBought: 0,
    //         img: require('./img/picture1.jpg')
    //     }
    // ])
    // const [cups, setcups] = useState([
    //     {
    //         id: 5,
    //         label: "cup",
    //         count: 1,
    //         price: "10.00",
    //         amtBought: 0,
    //         img: require('./img/picture1.jpg')
    //     }
    // ])
    // const [decorations, setdecorations] = useState([
    //     {
    //         id: 6,
    //         label: "decoration",
    //         count: 1,
    //         price: "10.00",
    //         amtBought: 0,
    //         img: require('./img/picture1.jpg')
    //     }
    // ])
    // const [cartlist, addcart] = useState([])

    // const addItem = (obj) => {
    //     addcart([...cartlist, {
    //         label: obj.label,
    //         amtBought: 1,
    //         price: obj.price,
    //         img: obj.img
    //     }])
    // }

    // let plateList = plates.map(pl => {
    //     return (
    //         // change key to img
    //         <div className="item" key={pl.id}>
    //             <div className="item-image">
    //                 <img src={pl.img} alt="item" />
    //             </div >
    //             <div className="item-hover">
    //                 <div className="price">
    //                     USD {pl.price}
    //                 </div>
    //                 <div className="add-to-cart" onClick={() => addItem(pl)}>
    //                     Add to cart
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // })
    // let bowlList = bowls.map(bo => {
    //     return (
    //         <div className="item" key={bo.id}>
    //             <div className="item-image">
    //                 <img src={bo.img} alt="item" />
    //             </div >
    //             <div className="item-content">
    //                 <p>Price: {bo.price}</p>
    //             </div>
    //         </div>
    //     )
    // })


    return (
        <div className="general">
            <div className="background">
                <div className="works-title">
                    Works
                </div>
                <div className="altx">
                    <div className="altxline1"></div>
                    <div className="altxline2"></div>
                </div>
                <div className="categories">
                    <div className="category-boxing" id="plateSection">
                        <div className="left-title">
                            <div>
                                <Link
                                    to="plateSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="yellowLink">| Plates</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="bowlSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Bowls</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="cupSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Cups</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="decorSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Decor</a>
                                </Link>
                            </div>
                        </div>
                        <div className="category-flex">
                            <div className="category-title">
                                Plates
                                <br />
                                <div className="underline">______________</div>
                            </div>
                            <div className="category-items">
                                {/* <div className="workrow"> */}
                                <div className="row_item item1">
                                    <a href="plate1">
                                        <img src={pl1} alt="plate 1" />
                                    </a>
                                </div>
                                <div className="row_item item2">
                                    <a href="plate1">
                                        <img src={pl1} alt="plate 1" />
                                    </a>
                                </div>
                                <div className="row_item item3">
                                    <a href="plate1">
                                        <img src={pl1} alt="plate 1" />
                                    </a>
                                </div>
                                {/* </div> */}

                                {/* <div id="s-plate0">
                                    <a href="#l-plate0">
                                        <img className="piece_h" src={plate0} alt="plate 0" />
                                    </a>
                                    <a href="#s-plate0" class="overlay" id="l-plate0">
                                        <img className="overlay_h" src={plate0} alt="plate 0" />
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="category-boxing" id="bowlSection">
                        <div className="left-title">
                            <div>
                                <Link
                                    to="plateSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Plates</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="bowlSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="yellowLink">| Bowls</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="cupSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Cups</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="decorSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Decor</a>
                                </Link>
                            </div>
                        </div>
                        <div className="category-flex">
                            <div className="category-title">
                                Bowls
                                <br />
                                <div className="underline">______________</div>
                            </div>
                            <div className="category-items">
                                <div id="s-bowl0">
                                    <a href="#l-bowl0">
                                        <img src={bowl0} alt="bowl 0" className="piece_h" />
                                    </a>
                                    <a href="#s-bowl0" class="overlay" id="l-bowl0">
                                        <img src={bowl0} alt="bowl 0" className="overlay_h" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="category-boxing" id="cupSection">
                        <div className="left-title">
                            <div>
                                <Link
                                    to="plateSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Plates</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="bowlSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Bowls</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="cupSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="yellowLink">| Cups</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="decorSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Decor</a>
                                </Link>
                            </div>
                        </div>
                        <div className="category-flex">
                            <div className="category-title">
                                Cups
                                <br />
                                <div className="underline">______________</div>
                            </div>
                            <div className="category-items">
                                <div id="s-cup0">
                                    <a href="#l-cup0">
                                        <img src={cup0} alt="cup 0" className="piece_h" />
                                    </a>
                                    <a href="#s-cup0" class="overlay" id="l-cup0">
                                        <img src={cup0} alt="cup 0" className="overlay_h" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="category-boxing" id="decorSection">
                        <div className="left-title">
                            <div>
                                <Link
                                    to="plateSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Plates</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="bowlSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Bowls</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="cupSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="whiteLink">| Cups</a>
                                </Link>
                            </div>
                            <br />
                            <div>
                                <Link
                                    to="decorSection"
                                    activeClass="active"
                                    spy={false}
                                    smooth={true}
                                    duration={700}
                                >
                                    <a href="" className="yellowLink">| Decor</a>
                                </Link>
                            </div>
                        </div>
                        <div className="category-flex">
                            <div className="category-title">
                                Decor
                                <br />
                                <div className="underline">______________</div>
                            </div>
                            <div className="category-items">
                                <div id="s-decor0">
                                    <a href="#l-decor0">
                                        <img src={decor0} alt="decor 0" className="piece_v" />
                                    </a>
                                    <a href="#s-decor0" class="overlay" id="l-decor0">
                                        <img src={decor0} alt="decor 0" className="overlay_v" />
                                    </a>
                                </div>
                                <div id="s-decor1">
                                    <a href="#l-decor1">
                                        <img src={decor1} alt="decor 1" className="piece_v" />
                                    </a>
                                    <a href="#s-decor1" class="overlay" id="l-decor1">
                                        <img src={decor1} alt="decor 1" className="overlay_v" />
                                    </a>
                                </div>
                                <div id="s-decor2">
                                    <a href="#l-decor2">
                                        <img src={decor2} alt="decor 2" className="piece_v" />
                                    </a>
                                    <a href="#s-decor2" class="overlay" id="l-decor2">
                                        <img src={decor2} alt="decor 2" className="overlay_v" />
                                    </a>
                                </div>
                                <div id="s-decor3">
                                    <a href="#l-decor3">
                                        <img src={decor3} alt="decor 3" className="piece_v" />
                                    </a>
                                    <a href="#s-decor3" class="overlay" id="l-decor3">
                                        <img src={decor3} alt="decor 3" className="overlay_v" />
                                    </a>
                                </div>
                                <div id="s-decor4">
                                    <a href="#l-decor4">
                                        <img src={decor4} alt="decor 4" className="piece_h" />
                                    </a>
                                    <a href="#s-decor4" class="overlay" id="l-decor4">
                                        <img src={decor4} alt="decor 4" className="overlay_h" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Works
