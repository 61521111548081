import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home'
import Navigation from './components/Navigation'
import About from './components/About'
import Works from './components/Works'
import Cart from './components/Cart'
import Contact from './components/Contact';
import ScrollToTop from './components/ScrollToTop'

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          <Navigation />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={About} />
            <Route path="/works" component={Works} />
            <Route path="/cart" component={Cart} />
          </Switch>
          <Contact />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
